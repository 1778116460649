import clsx from "clsx";
import GetStarted from "components/GetStarted/GetStarted";
import Footer from "components/footer/Footer";
import Navbar from "components/navbar/Navbar";
import Button from "components/shared/Button";
import WithPadding from "components/shared/WithPadding";
import { useEffect, useState } from "react";
import { ChevronDown, X } from "react-feather";
import { IoSearchOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { getRequest } from "utils/api";
import Line from "../../assets/images/aboutUs/line.png";
import style from "./Faq.module.scss";

export default function Faq() {
  const [activeCategory, setActiveCategory] = useState("General");
  const navigate = useNavigate();

  const [faqCategories, setFaqCategories] = useState([]);
  const [openFaqId, setOpenFaqId] = useState(0);

  async function fetchFaqCategories() {
    try {
      const resp = await getRequest("/web/faq-categories", { noAuth: true });

      setFaqCategories(resp.data);
    } catch {
    } finally {
    }
  }

  const activeFaqs = faqCategories.find((item) => item.name === activeCategory);

  function handleClickFaq(id) {
    if (openFaqId === id) return setOpenFaqId(null);
    setOpenFaqId(id);
  }

  const list = [
    "General",
    "Our Approach & Methodology",
    "Technical Aspects",
    "Benefits & Features",
    "Organizations & Collaborations",
    "Miscellaneous",
  ];

  useEffect(() => {
    fetchFaqCategories();
  }, []);

  return (
    <>
      <Navbar background={"white"} />
      <WithPadding className={style.wrapper}>
        <div className={style.main}>
          <div className={style.left}>
            <div className={style.title}>Frequently Asked Questions</div>
            <div className={style.list}>
              {list.map((list, index) => (
                <div
                  key={index}
                  className={clsx(style.listItem, {
                    [style.active]: activeCategory === list,
                  })}
                  onClick={() => {
                    setOpenFaqId(null);
                    setActiveCategory(list);
                  }}
                >
                  <span>
                    <img className={style.line} src={Line} alt="" />
                    {list}
                  </span>
                </div>
              ))}
            </div>

            <div className={style.info}>
              <div className={style.title}>
                Didn't find what you were <br /> looking for?
              </div>
              <div className={style.details}>
                We're here to help. Contact us directly through our contact
                page, or email us at <span>hello@careertest.ai</span>
              </div>
              <div className={style.btnContainer}>
                <Button
                  options={{ width: "120px", height: "unset" }}
                  onClick={() => navigate("/contact-us")}
                >
                  Contact Us
                </Button>
              </div>
            </div>
          </div>
          <div className={style.right}>
            <div>
              <div className={style.search_bar}>
                <input type="text" placeholder="Write your question here..." />
                <IoSearchOutline className={style.search_icon} />
              </div>
            </div>
            <div className={style.faqs}>
              {activeFaqs?.faqs?.map((faq) => (
                <div
                  className={clsx(
                    style.faq,
                    openFaqId === faq.id && style.open,
                  )}
                  key={faq.question}
                >
                  <div
                    onClick={() => handleClickFaq(faq.id)}
                    className={style.faqQuestion}
                  >
                    {faq.question}
                    <span>
                      {openFaqId === faq.id ? <X /> : <ChevronDown />}
                    </span>
                  </div>
                  <div className={style.faqAnswer}>{faq.answer}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </WithPadding>
      <GetStarted
        text={"Ready to Shape Your Future?"}
        subText={"Start your personalized career assessment now"}
        buttonText={"Get Started"}
      />

      <Footer />
    </>
  );
}
