import clsx from "clsx";
import { ErrorMessage, useField } from "formik";
import ReactInputMask from "react-input-mask";
import styleError from "./Error.module.scss";

export default function TextField({ label, className, ...props }) {
  const [field, meta] = useField(props);
  return (
    <div
      className={clsx(
        className,
        meta.touched && meta.error && styleError.error,
      )}
    >
      {props.type === "tel" ? (
        <ReactInputMask {...field} {...props} />
      ) : (
        <input {...field} {...props} />
      )}

      <ErrorMessage
        name={field.name}
        render={(msg) => <div className={styleError.wrapper}>{msg}</div>}
      />
    </div>
  );
}
