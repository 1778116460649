import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { withStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Aboutus from "assets/images/aboutUs/Image Placeholder.png";
import clsx from "clsx";
import GetStarted from "components/GetStarted/GetStarted";
import Footer from "components/footer/Footer";
import Navbar from "components/navbar/Navbar";
import Button from "components/shared/Button";
import InlineLoader from "components/shared/InlineLoader";
import Text from "components/shared/formik/TextField";
import { Formik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { postRequest } from "utils/api";
import { splitArrChunks } from "utils/helper";
import style from "./ContactUs.module.scss";

const Buttons = [
  { caption: "Send Whatsapp Message", link: "https://wa.me/+7593929394" },
  {
    caption: "Career Assessment & Counselling",
    link: "/post-assessment",
  },

  {
    caption: "Certified Career Development Executive (CCDE)",
    link: "/ccde",
  },

  { caption: "Schools | Colleges | Organizations", link: "/institutions" },

  {
    caption: "CareerTest Partner Program",
    link: "/partner-program",
  },
];

const themePurple = "rgba(224, 227, 230, 1)";
const radioSize = "18px";
const CustomRadio = withStyles({
  root: {
    color: "rgba(224, 227, 230, 1)",
    "&$checked": {
      color: "#000",
    },
    "& svg": {
      width: radioSize,
      height: radioSize,
    },
    "&$disabled": {
      color: "rgba(224, 227, 230, 1)",
    },
  },

  checked: {},
  disabled: {},
})((props) => <Radio {...props} />);

export default function ContactUs() {
  function onClick(link) {
    window.open(link);
  }

  const matches = useMediaQuery("(max-width:600px)");

  const [showSuccess, setShowSuccess] = useState(false);

  const [selected, setSelected] = useState("");
  const [isErrorDept, setIsErrorDept] = useState(false);
  const [isErrorEmail, setIsErrorEmail] = useState(false);
  const [isErrorLang, setIsErrorLang] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [postErrors, setPostErrors] = useState({});

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    category: "",
    message: "",
  });

  const navigate = useNavigate();

  const _ArrSelectors = matches
    ? [
      { name: "High School Students", id: "highschoolstudents" },
      { name: "College Students & Graduates", id: "collegestudents" },
      { name: "" },
      { name: "Working Professionals", id: "workingprofessionals" },
      { name: "Organization", id: "organization" },
      { name: "Other", id: "other" },
    ]
    : [
      { name: "High School Students", id: "highschoolstudents" },
      { name: "College Students & Graduates", id: "collegestudents" },
      { name: "" },
      { name: "Working Professionals", id: "workingprofessionals" },
      { name: "Organization", id: "organization" },
      { name: "Other", id: "other" },
    ];

  function handleChangeRadioSelect(e) {
    setIsErrorDept(false);
    setSelected(e.target.value);
  }

  function handleChange(e) {
    setPostErrors([]);
    setIsErrorLang(false);

    const { name, value } = e.target;

    setFormData((prev) => ({ ...prev, [name]: value }));
  }

  function submitForm() {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    // if (!selected.length) return setIsErrorDept(true);
    if (!emailPattern.test(formData.email)) return setIsErrorEmail(true);

    // if (!formData.pref_lang || formData.pref_lang.length <= 0)
    //   return setIsErrorLang(true);

    const { name, email, category, message } = formData;

    const postData = {
      name,
      email,
      category,
      message,
    };

    setIsLoading(true);
    postRequest(`/web/contact`, postData, { noAuth: true })
      .then(() => {
        setShowSuccess(true);

        setFormData({
          name: "",
          email: "",
          category: null,
          message: "",
        });
      })
      .catch((err) => {
        console.log(err);
        setPostErrors(err.response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <div className={style.wrapper}>
      <Navbar background={"white"} />
      <div className={style.main}>
        <div className={style.left}>
          <div className={style.welcome}>
            <div className={style.title}>We'd Love to Hear from You</div>
            <div className={style.askquestion}>
              Questions, feedback, or just a hello - reach out to us. Drop us an
              email:
              <br />
              <span>hello@careertest.ai</span>
            </div>

            <Formik
              initialValues={{
                full_name: "",
                email: "",
                category: "",
                message: "",
              }}
              onSubmit={submitForm}
            // validationSchema={validationSchema}
            >
              {({ values, errors, touched, handleBlur, handleSubmit }) => (
                <form onSubmit={handleSubmit} onChange={handleChange}>
                  <div className={style.textGroup}>
                    <div className={style.row}>
                      <label htmlFor="name">Full Name</label>
                      <Text
                        className={clsx(style.textField, style.firstName)}
                        type="text"
                        name="name"
                        value={formData.name}
                        label="Name"
                        placeholder="Input your full name in here"
                      />
                    </div>

                    <div className={style.row}>
                      <label htmlFor="email">Your Email</label>

                      <Text
                        className={style.textField}
                        type="email"
                        name="email"
                        value={formData.email}
                        label="Email"
                        placeholder="Input your email address in here "
                      />
                    </div>
                  </div>
                  {isErrorEmail && (
                    <div className={clsx(style.error, style.usernameError)}>
                      Enter Valid Email as your username
                    </div>
                  )}

                  {postErrors.username && (
                    <div className={style.errors}>
                      {postErrors.username.map((err) => (
                        <div className={style.error}>{err.toLowerCase()}</div>
                      ))}
                    </div>
                  )}

                  <div className={style.category}>
                    <label htmlFor="category" className={style.category}>
                      Category
                    </label>

                    <RadioGroup
                      aria-label="answer"
                      name="category"
                      value={formData.category}
                      onChange={handleChangeRadioSelect}
                      className={style.selectors}
                    >
                      {matches
                        ? splitArrChunks(_ArrSelectors, 3).map(
                          (selPair, pairIndex) => (
                            <div
                              key={pairIndex}
                              className={style.selectorPair}
                            >
                              {selPair.map((sel, index) => (
                                <div key={index} className={style.selector}>
                                  <label className={style.name}>
                                    {!sel.name ? null : (
                                      <CustomRadio
                                        value={sel.name}
                                        checked={
                                          formData.category === sel.name
                                        }
                                      />
                                    )}
                                    {sel.name}
                                  </label>
                                </div>
                              ))}
                            </div>
                          ),
                        )
                        : splitArrChunks(_ArrSelectors, 3).map(
                          (selPair, pairIndex) => (
                            <div
                              key={pairIndex}
                              className={style.selectorPair}
                            >
                              {selPair.map((sel, index) => (
                                <div key={index} className={style.selector}>
                                  <label className={style.name}>
                                    {!sel.name ? null : (
                                      <CustomRadio
                                        value={sel.name}
                                        checked={
                                          formData.category === sel.name
                                        }
                                      />
                                    )}
                                    {sel.name}
                                  </label>
                                </div>
                              ))}
                            </div>
                          ),
                        )}
                    </RadioGroup>
                    {isErrorDept && (
                      <div className={clsx(style.error, style.deptError)}>
                        Choose your Category
                      </div>
                    )}
                  </div>

                  <div className={style.textareamain}>
                    <div className={style.message}>
                      <label htmlFor="message">Message</label>

                      <textarea
                        className={style.textarea}
                        // id="outlined-multiline-static"
                        placeholder="Write your message here"
                        name="message"
                        value={formData.message}
                        rows={4}
                      // variant="outlined"
                      />
                    </div>
                  </div>

                  <div className={style.btnContainer}>
                    {/* <div>
                    already registered ?{" "}
                    <span onClick={handleNavigateLogin}>Login</span>
                  </div> */}

                    <Button
                      className={style.btn}
                      submit
                      options={{
                        width: "237px",
                        height: "55px",
                        radius: "10px",
                      }}
                    >
                      {isLoading ? (
                        <InlineLoader size={20} color="white" />
                      ) : (
                        "Send Message"
                      )}
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
            {showSuccess && (
              <div
                className={style.success}
                style={{ marginTop: "10px", marginLeft: "10px" }}
              >
                Thank you! We'll contact you soon...
              </div>
            )}
          </div>
        </div>

        <div className={style.right}>
          <img src={Aboutus} alt="images" className={style.img} />
        </div>
      </div>
      <GetStarted
        text={"Ready to Shape Your Future?"}
        subText={"Start your personalized career assessment now"}
        buttonText={"Get Started"}
      />
      <Footer />
    </div>

    // {/* <div className={style.footer}>
    // </div> */}
  );
}
